<template>
  <AddBinsDialog
    value="true"
    :loading="loading"
    :start-number="startNumber"
    :bin="bin"
    @ok="onOk"
    @cancel="onCancel"
  />
</template>

<script>
import { BIN_BATCH_CREATE } from "./graphql";
import AddBinsDialog from "./components/AddBinsDialog";

export default {
  name: "AddBinsDialogView",
  components: { AddBinsDialog },
  props: {
    startNumber: {
      type: [String, Number],
      required: true,
    },
    bin: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    loading: false,
  }),
  methods: {
    onCancel() {
      this.$router.back();
    },
    async onOk(values) {
      this.loading = true;

      await this.$apollo.mutate({
        mutation: BIN_BATCH_CREATE,
        variables: {
          input: {
            quantity: values.number,
            sendLabelsTo: values.templateEmail,
          },
        },
      });

      this.loading = false;
      this.$router.back();
    },
  },
};
</script>
