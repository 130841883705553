<template>
  <VxDialog
    v-model="modelValue"
    :h-size="'xsmall'"
    v-bind="$props"
    :title="$t('backstock.setup.bins.addBinsDialog.title')"
    persistent
    @back="cancel"
  >
    <VxForm ref="form" @submit="ok">
      <v-container>
        <VxTextField
          v-model.number="form.number"
          type="number"
          name="numberOfBins"
          :label="$t('backstock.setup.bins.addBinsDialog.numberOfBinsLabel')"
          :placeholder="
            $t('backstock.setup.bins.addBinsDialog.numberOfBinsPlaceholder')
          "
          rules="required|min_value:1"
          min="1"
        />
        <p>
          {{
            $t("backstock.setup.bins.addBinsDialog.nextBinNumberText", {
              startNumber,
            })
          }}
        </p>
        <p>
          {{
            $t("backstock.setup.bins.addBinsDialog.templateEmailDescription")
          }}
        </p>
        <VxTextField
          v-model="form.templateEmail"
          :label="$t('backstock.setup.bins.addBinsDialog.templateEmailLabel')"
          name="templateEmail"
          :placeholder="
            $t('backstock.setup.bins.addBinsDialog.templateEmailPlaceholder')
          "
          rules="required|email"
        />
      </v-container>
    </VxForm>

    <template #actions>
      <VxBtn secondary @click="cancel">
        {{ $t("backstock.setup.bins.addBinsDialog.cancelButtonText") }}
      </VxBtn>
      <VxBtn text :loading="loading" @click="submit">
        {{ $t("backstock.setup.bins.addBinsDialog.okButtonText") }}
      </VxBtn>
    </template>
  </VxDialog>
</template>

<script>
import { useFormObject } from "@/mixins/useFormObject";
import {
  VxTextField,
  VxDialog,
  VxBtn,
  VxForm,
  useModelValue,
  useResponsiveness,
} from "@/core-ui";

export default {
  name: "AddBinsDialog",
  components: { VxDialog, VxTextField, VxBtn, VxForm },
  mixins: [
    useModelValue(),
    useResponsiveness(),
    useFormObject({
      prop: "bin",
      default: {
        number: 0,
        templateEmail: "",
      },
    }),
  ],
  props: {
    startNumber: {
      type: [String, Number],
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getValidator() {
      return this.$refs.form;
    },
    submit() {
      this.$refs.form.submit();
    },
    cancel() {
      this.modelValue = false;
      this.$emit("cancel");
    },
    ok() {
      this.modelValue = false;
      this.$emit("ok", { ...this.form });
    },
  },
};
</script>
